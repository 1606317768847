import {SemanticICONS} from 'semantic-ui-react/dist/commonjs/generic';
import {DataType} from '../../const/crud';
import {Company} from '../../models/Company';
import {User} from '../../models/User';
import {SortOrder} from './DataTable';

export enum CRUDType {
  CANCEL = 'cancel',
  CREATE = 'create',
  READ = 'read',
  READ_ONE = 'read-one',
  UPDATE = 'update',
  DELETE = 'remove',
  UPLOAD_MEDIA = 'upload-media',
  GET_MEDIA = 'get-media',
  DELETE_MEDIA = 'delete-media',
  SEARCH = 'search',
  COUNT = 'count',
  TOTAL = 'total',
  IMPORT_FILE = 'import-file',
  AUTH_XERO = 'auth-xero',
  AUTH_QUICKBOOKS = 'auth-quickbooks',
}

export enum DataActionType {
  CREATE_NOTE = 'create-note',
  DELETE_NOTE = 'delete-note',
  CREATE_MEDIA = 'create-media',
  EDIT_MEDIA = 'edit-media',
  DELETE_MEDIA = 'delete-media',
  CREATE_ATTACHMENT = 'create-attachment',
  DELETE_ATTACHMENT = 'delete-attachment',
  CREATE_LOCATION = 'create-location',
  DELETE_LOCATION = 'delete-location',
  CREATE_CONTACT = 'create-contact',
  EDIT_CONTACT = 'edit-contact',
  DELETE_CONTACT = 'delete-contact',
  CREATE_CHECKLIST = 'create-checklist',
  EDIT_CHECKLIST = 'edit-checklist',
  DELETE_CHECKLIST = 'delete-checklist',
  CREATE_CHECKLIST_TEMPLATE = 'create-checklist-template',
  EDIT_CHECKLIST_TEMPLATE = 'edit-checklist-template',
  DELETE_CHECKLIST_TEMPLATE = 'delete-checklist-template',
  IMPORT_CHECKLIST_TEMPLATE = 'import-checklist-template',
  IMPORT_XERO_CUSTOMER = 'import-xero-customer',
  IMPORT_QUICKBOOKS_CUSTOMER = 'import-quickbooks-customer',
  CREATE_PAYMENT = 'create-payment',
  EDIT_PAYMENT = 'edit-payment',
  DELETE_PAYMENT = 'delete-payment',
  CREATE_COST_GROUP = 'create-cost-group',
  EDIT_COST_GROUP = 'edit-cost-group',
  DELETE_COST_GROUP = 'delete-cost-group',
  IMPORT_COST_GROUP = 'import-cost-group',
  CREATE_COST = 'create-cost',
  EDIT_COST = 'edit-cost',
  DELETE_COST = 'delete-cost',
  CREATE_JOB_CODE = 'create-job-code',
  EDIT_JOB_CODE = 'edit-job-code',
  DELETE_JOB_CODE = 'delete-job-code',
  CREATE_THEME = 'create-theme',
  EDIT_THEME = 'edit-theme',
  DELETE_THEME = 'delete-theme',
  CREATE_LEAD_SOURCE = 'create-lead-source',
  EDIT_LEAD_SOURCE = 'edit-lead-source',
  DELETE_LEAD_SOURCE = 'delete-lead-source',
  CREATE_STAFF_AVAILABILITY = 'create-staff-availability',
  EDIT_STAFF_AVAILABILITY = 'edit-staff-availability',
  DELETE_STAFF_AVAILABILITY = 'delete-staff-availability',
  CREATE_CUSTOM_FIELD = 'create-custom-field',
  EDIT_CUSTOM_FIELD = 'edit-custom-field',
  DELETE_CUSTOM_FIELD = 'delete-custom-field',
  CREATE_CUSTOMER_JOB = 'create-customer-job',
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

// Data Table
export interface DataTableProps {
  loggedInUser?: User;
  loggedInCompany?: Company;
  hideFilter?: boolean;
  setHideFilter?: any;
  style?: any;
  title: any;
  titleIcon?: any;
  headline?: any;
  description?: any;
  className?: string;
  data: any[];
  dataType: string;
  primaryAction?: DataActionType | CRUDType;
  primaryActionTitle?: string;
  primaryActionClass?: string;
  disabledPrimaryAction?: boolean;
  clickAction?: DataActionType | CRUDType;
  actions?: Action[];
  handleAction?: any;
  cols: Column[];
  addParams?: any;
  width?: number;
  pageSize?: number;
  sortField?: string[];
  sortDirection?: SortDirection[];
  metrics?: any;
  calendar?: boolean;
  calendarData?: any[];
  calendarAction?: any;
  paginationAction?: any;
  collectionTotal?: number;
  apiSortOrder?: SortOrder;
  additional?: any;
  export?: boolean;
  crudAction?: any;
}

export interface CalendarEvent {
  _id: string;
  title: string;
  label: string;
  start: Date;
  end: Date;
  allDay?: boolean;
  resource?: any;
  backgroundColor?: string;
}

export interface Action {
  title: string;
  icon?: SemanticICONS;
  className?: string;
  action: CRUDType | DataActionType;
  data?: any;
  dataType?: DataType;
}

export interface Column {
  title: string;
  dataField: string;
  dataProcess?: {
    condition?: {
      field: string;
      value: string;
    };
    action: any;
    options?: DropdownOption[];
    data?: any;
    field?: string;
    dataField?: string;
  };
  type: DataTableType;
  mobileDisplay: boolean;
  className?: any;
  width?: number;
}

export enum DataTableType {
  PLAIN = 'plain',
  STRING = 'string',
  DATE = 'date',
  DATE_TIME = 'date-time',
  EMAIL = 'email',
  POSTAL_CODE = 'postal-code',
  NUMBER = 'number',
  MEDIA = 'media',
  ATTACHMENT = 'attachment',
  CURRENCY = 'currency',
  STAGE = 'stage',
  STATUS_CHECK = 'status-check',
  JOB_PREFIX_ID = 'job-prefix-id',
  BUILD_INVOICE_PDF = 'build-invoice-pdf',
}

export enum ValidationType {
  REQUIRED = 'required',
  STAGE_ASSIGNED = 'stage-assigned',
}

// Data Modal
export interface DataModalProps {
  showDataForm: {
    status: boolean;
    formType: CRUDType;
  };
  title: string;
  data: any;
  dataType: string;
  sections: Section[];
  onClose: any;
  crudAction: any;
  buildOptions?: any;
  hideDelete?: boolean;
  searchAction?: any;
  setFormData?: any;
}

export interface Section {
  scrollable?: boolean;
  scrollTitle?: string;
  title?: string;
  titleIcon?: any;
  description?: any;
  tab?: string;
  tabTitle?: string;
  selectedTab?: number | string;
  onTabChange?: any;
  fields: Field[];
  hideSection?: boolean;
}

export interface Field {
  disabled?: string;
  className?: string;
  title?: string;
  description?: any;
  icon?: string;
  placeholder?: string;
  optional?: boolean | 'hidden';
  value?: any;
  type: DataFormType;
  dataField: string;
  dataSubField?: string;
  dataType?: DataType;
  dataSet?: any;
  validation?: ValidationType[];
  error?: boolean;
  content?: any;
  subSection?: boolean;
  lookup?: {
    dataSet: any[];
    field: string;
  };
  label?: boolean;
  allowAdditions?: boolean;
  handleAddition?: any;
  onChange?: any;
  hidden?: boolean;
  characterLimit?: number;
  showIf?: {field: string; value: 'string'};
}

// Data Form
export interface DataFormProps {
  scrollRefs?: any;
  scrollAction?: any;
  sections: Section[];
  data: any;
  dataType: string;
  onChange: any;
  onSearch?: any;
  loggedInCompany?: Company;
  loggedInUser?: User;
}

export enum DataFormType {
  BUTTON = 'button',
  TEXT = 'text',
  TEXT_READ_ONLY ='text-read-only',
  TEXT_CAPITALISE = 'text-capitalise',
  TEXT_LOWER = 'text-lower',
  TEXT_UPPER = 'text-upper',
  TEXT_AREA = 'text-area',
  SELECT = 'select',
  SELECT_BOOLEAN = 'select-boolean',
  SELECT_MULTIPLE = 'search-multiple',
  SEARCH_SELECT = 'search-select',
  SEARCH_SELECT_API = 'search-select-api',
  SEARCH_SELECT_API_CUSTOMER = 'search-select-api-customer',
  SEARCH_SELECT_API_MULTIPLE = 'search-select-api-multiple',
  SELECT_CONTACT = 'select-contact',
  DATE = 'date',
  DATE_TIME_RANGE = 'date-time-range',
  DATE_RANGE = 'date-range',
  DATE_TIME = 'date-time',
  NUMBER = 'number',
  CURRENCY = 'currency',
  TABLE = 'table',
  MEDIA_UPLOAD = 'media-upload',
  ATTACHMENT_UPLOAD = 'attachment-upload',
  BUILD_PDF = 'build-pdf',
  DATE_SWITCH = 'date-switch',
  SWITCH = 'switch',
  PASSWORD_RESET = 'password-reset',
  CHECKLIST_TEMPLATE = 'checklist-template',
  XERO_CUSTOMER_SEARCH = 'xero-customer-search',
  QUICKBOOKS_CUSTOMER_SEARCH = 'quickbooks-customer-search',
  PRICE_NET = 'price-net',
  PRICE_VAT = 'price-vat',
  PRICE_TOTAL = 'price-total',
  PRICE_OWED = 'price-owed',
  PRICE_PAID = 'price-paid',
  PRICE_INVOICED = 'price-invoiced',
  PRICE_WRITTEN_OFF = 'price-written-off',
  COST_GROUP = 'cost-group',
  BUILD_COST_PDF = 'build-cost-pdf',
  JOB_ID_SELECTOR = 'job-id-selector',
  GOOGLE_ADDRESS = 'google-address',
  ADDRESS_LOOKUP = 'address-lookup',
}

export interface DropdownOption {
  key: string;
  text: string;
  description?: DropdownOptionDescription[];
  value: string;
  label?: string;
}

export interface DropdownOptionDescription {
  title: string;
  data: string;
  toUpper?: boolean;
}

export enum LookupType {
  ADDRESS = 'address',
  CONTACT = 'contact',
}

// Data Filter
export interface DataFilterProps {
  sections: Section[];
  data: any;
  setData: any;
  dataType: string;
  primaryAction: any;
  searchAction: any;
}
