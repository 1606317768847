import {cloneDeep, isEqual, set, startCase} from 'lodash';
import moment from 'moment';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Modal} from 'semantic-ui-react';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import {DataType} from '../../const/crud';
import {JobEnums} from '../../models/Job';
import {CRUDType, DataModalProps, Field, Section} from './DataConst';
import DataForm from './DataForm';
import {
  validateSectionFieldsOnChange,
  validateSectionsOnSubmit,
} from './DataValidation';

const confirmDataSets = (
  sections: Section[],
  newSections: Section[],
): boolean => {
  let dataSets = sections.map((section) => getDataSet(section));
  let newDataSets = newSections.map((section) => getDataSet(section));

  return isEqual(dataSets, newDataSets);
};

const confirmTabChange = (
  sections: Section[],
  newSections: Section[],
): boolean => {
  const stages = sections.filter((s) => s.tab === 'stages');
  const newStages = newSections.filter((s) => s.tab === 'stages');

  for (let i = 0; i < stages.length; i++) {
    if (stages[i].selectedTab !== newStages[i].selectedTab) {
      return true;
    }
  }

  return false;
};

const getDataSet = (section) => {
  let dataSets: any[] = [];
  for (const field of section.fields) {
    if (field.dataSet) {
      dataSets.push(field.dataSet);
    }
  }
  return dataSets;
};

const DataModal: React.FunctionComponent<DataModalProps> = (
  props: DataModalProps,
) => {
  const [prevData, setPrevData] = useState();
  const [data, setData] = useState(props.data);
  const [sections, setSections] = useState(props.sections);

  const completeAction = (CRUDType?: CRUDType, action?: any) => {
    props.crudAction(
      CRUDType || props.showDataForm.formType,
      props.dataType,
      data.selected,
      undefined,
      undefined,
      action,
    );

    setPrevData(data);
  };

  useEffect(() => {
    if (!isEqual(sections, props.sections)) {
      if (
        !confirmDataSets(sections, props.sections) ||
        confirmTabChange(sections, props.sections)
      ) {
        setSections(props.sections);
      }
    }

    const hasPrevData = prevData;
    let propsDataSameAsPrevData = isEqual(prevData, props.data);
    const propsDataSameAsData = isEqual(data, props.data);

    if ((!hasPrevData && props.data) || !propsDataSameAsPrevData) {
      if (!propsDataSameAsData) {
        if (props.data.selected._id !== data.selected._id) {
          setSections(props.sections);
          setData(props.data);
          setPrevData(props.data);

          if (props.setFormData) {
            props.setFormData(props.data);
          }

          return;
        }

        const cloned = cloneDeep(data);
        const selected = props.data.selected;

        if (props.dataType === DataType.CUSTOMER) {
          if (selected.contact) {
            cloned.selected.contact = selected.contact;
          }

          if (selected.companyName) {
            cloned.selected.companyName = selected.companyName;
          }
        }

        if (selected.attachments) {
          cloned.selected.attachments = selected.attachments;
        }

        if (selected.locations) {
          cloned.selected.locations = selected.locations;
        }

        if (selected.pricing) {
          cloned.selected.pricing = selected.pricing;
        }

        if (selected.costs) {
          cloned.selected.costs = selected.costs;
        }

        if (selected.costReport) {
          cloned.selected.costReport = selected.costReport;
        }

        if (selected.notes) {
          cloned.selected.notes = selected.notes;
        }

        if (selected.theme) {
          cloned.selected.theme = selected.theme;
        }

        if (selected.checklists) {
          cloned.selected.checklists = selected.checklists;
        }

        if (selected.costGroups) {
          cloned.selected.costGroups = selected.costGroups;
        }

        if (selected.jobCodes) {
          cloned.selected.jobCodes = selected.jobCodes;
        }

        if (selected.customFields) {
          cloned.selected.customFields = selected.customFields;
        }

        if (selected.staffAvailability) {
          cloned.selected.staffAvailability = selected.staffAvailability;
        }

        if (selected.leadSources) {
          cloned.selected.leadSources = selected.leadSources;
        }

        if (selected.stages) {
          const options = JobEnums.JobStageOptions(
            props.data.loggedInCompany.stageTitles,
          );
          options.map((stage) => {
            if (selected.stages[stage.value].status) {
              cloned.selected.stages[stage.value].status =
                selected.stages[stage.value].status;
            }
            if (selected.stages[stage.value].media) {
              cloned.selected.stages[stage.value].media =
                selected.stages[stage.value].media;
            }
            if (selected.stages[stage.value].notes) {
              cloned.selected.stages[stage.value].notes =
                selected.stages[stage.value].notes;
            }
            if (selected.stages[stage.value].checklist) {
              cloned.selected.stages[stage.value].checklist =
                selected.stages[stage.value].checklist;
            }
            if (selected.stages[stage.value].additional) {
              cloned.selected.stages[stage.value].additional =
                selected.stages[stage.value].additional;
            }
            if (selected.stages[stage.value].signature) {
              cloned.selected.stages[stage.value].signature =
                selected.stages[stage.value].signature;
            }
            if (selected.stages[stage.value].report) {
              cloned.selected.stages[stage.value].report =
                selected.stages[stage.value].report;
            }
            return null;
          });
        }

        setSections(props.sections);
        setData(cloned);
        setPrevData(props.data);

        if (props.setFormData) {
          props.setFormData(cloned);
        }
      } else {
        setSections(props.sections);
        setData(props.data);
        setPrevData(props.data);

        if (props.setFormData) {
          props.setFormData(props.data);
        }
      }
    }
  }, [
    props.data,
    data,
    props.sections,
    sections,
    prevData,
    props.dataType,
    props.setFormData,
    props,
  ]);

  const onChange = useCallback(
    (
      newVal: string | string[],
      field: Field | Field[],
      isDate: boolean,
      isDateTime: boolean,
      isDateRange: boolean,
      isDateTimeRange: boolean,
      allDayFields: Record<string, boolean>[],
      isClearDateRange?: boolean,
    ) => {
      // Clear date range
      if (isClearDateRange) {
        newVal = [null, null];
      }

      // Fix date range
      if (isDateRange) {
        newVal = [
          moment(newVal[0], 'DD-MM-YYYY')
            .hours(0)
            .minutes(0)
            .seconds(0)
            .toISOString(),
          newVal[1]
            ? moment(newVal[1], 'DD-MM-YYYY')
                .endOf('day')
                .toISOString()
            : null,
        ];
      }

      // Fix date time range
      if (isDateTimeRange) {
        newVal = [
          moment(newVal[0], 'DD-MM-YYYY HH:mm').toISOString(),
          newVal[1]
            ? moment(newVal[1], 'DD-MM-YYYY HH:mm').toISOString()
            : null,
        ];
      }

      // Fix date
      if (isDate) {
        newVal = moment(newVal, 'DD-MM-YYYY').toISOString();
      }

      if (isDateTime) {
        newVal = moment(newVal, 'DD-MM-YYYY HH:mm').toISOString();
      }

      if (!Array.isArray(newVal)) {
        newVal = [newVal];
      }

      if (!Array.isArray(field)) {
        field = [field];
      }

      let updatedData = cloneDeep(data);

      if (allDayFields) {
        allDayFields.forEach((obj) => {
          updatedData = set(updatedData, `selected.${obj.field}`, obj.value);
        });
      }

      newVal.forEach((i, index) => {
        updatedData = set(updatedData, `selected.${field[index].dataField}`, i);

        if (props.setFormData) {
          props.setFormData(updatedData);
        }

        // Validation
        if (field[index].validation) {
          const validatedSections: Section[] = sections.map((section) =>
            validateSectionFieldsOnChange(
              i,
              field[index],
              section,
              data.selected,
            ),
          );

          setSections(validatedSections);
        }

        // Handle additional onChange
        if (field[index].onChange) {
          field[index].onChange(updatedData);
        }
      });

      setData(updatedData);
    },
    [props, data, sections],
  );

  const itemName = props.data.selected.name
    ? props.data.selected.name
    : props.data.selected.firstName
    ? `${props.data.selected.firstName} ${props.data.selected.lastName}`
    : null;

  const scrollRefs = useRef([]);

  const scrollToSection = (ref) => {
    scrollRefs.current[ref].scrollIntoView({behavior: 'smooth'});
  };

  useEffect(() => {
    scrollRefs.current = scrollRefs.current.slice(0, sections.length);
  }, [sections]);

  return (
    <Modal
      className={'DataModalContainer'}
      dimmer="inverted"
      open={props.showDataForm.status}
      onClose={props.onClose}
      size={'fullscreen'}
      centered={false}
    >
      <Modal.Header>
        {itemName
          ? `${startCase(props.showDataForm.formType)} ${itemName}`
          : `${startCase(props.showDataForm.formType)} ${startCase(
              props.dataType,
            )}`}
      </Modal.Header>
      <Modal.Content
        scrolling
        className={props.dataType === DataType.JOB ? 'JobScrollableForm' : ''}
      >
        {props.dataType === DataType.JOB && (
          <div className={'JobScroller'}>
            {props.sections
              .filter((s) => {
                if (
                  (!props.data.loggedInUser?.permissions?.financial?.job
                    ?.pricing &&
                    s.scrollTitle === 'Job Pricing') ||
                  (!props.data.loggedInUser?.permissions?.financial?.job
                    .payments &&
                    s.scrollTitle === 'Payments & Invoices') ||
                  (!props.data.loggedInUser?.permissions?.financial?.job
                    ?.costs &&
                    s.scrollTitle === 'Job Costs')
                ) {
                  return false;
                }

                return s.scrollable && !s.tab;
              })
              .map((s, i) => (
                <button
                  key={`button-scroll-${s.title}-${i}`}
                  onClick={() => scrollToSection(i + 1)}
                >
                  {s.scrollTitle}
                </button>
              ))}
            <button
              onClick={() =>
                scrollToSection(
                  props.sections.filter((s) => s.scrollable && !s.tab).length +
                    1,
                )
              }
            >
              Stages
            </button>
          </div>
        )}

        <DataForm
          scrollRefs={scrollRefs}
          sections={sections}
          data={data.selected}
          dataType={props.dataType}
          onChange={onChange}
          onSearch={props.searchAction}
          loggedInCompany={props.data.loggedInCompany}
          loggedInUser={props.data.loggedInUser}
        />
      </Modal.Content>
      <Modal.Actions>
        {!props.hideDelete && props.showDataForm.formType !== CRUDType.CREATE && (
          <Button
            className="ui redButton"
            type="submit"
            icon
            labelPosition="left"
            floated="left"
            onClick={() => completeAction(CRUDType.DELETE)}
          >
            <Icon name={'delete'} />
            Delete
          </Button>
        )}
        <Button
          className="ui primary"
          type="submit"
          icon
          labelPosition="left"
          disabled={!validateSectionsOnSubmit(sections, data.selected).status}
          onClick={() => {
            const validationResult = validateSectionsOnSubmit(
              sections,
              data.selected,
            );

            !validationResult.status
              ? setSections(validationResult.sections)
              : completeAction();
          }}
        >
          <Icon name={'check'} />
          Submit
        </Button>
        <Button
          className="ui secondary"
          type="submit"
          onClick={() => completeAction(CRUDType.CANCEL, props.onClose)}
          icon
          labelPosition={'left'}
        >
          <Icon name={'delete'} />
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DataModal;
